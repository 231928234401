/* This is a CSS file */

.text-input--underbar {
  padding: 0;
  margin: 0;
  font: inherit;
  color: inherit;
  background: transparent;
  border: none;
  line-height: normal;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  vertical-align: top;
  outline: none;
  line-height: 1;
  font-family: 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-size: 17px;
  letter-spacing: 0;
  border: 1px solid #ddd;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  background-color: #f9f9f9;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #1f1f21;
  padding: 4px 8px 0 8px;
  margin: 0;
  font-size: 15px;
  height: 31px;
  font-weight: 400;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border: none;
  background-color: transparent;
  border-bottom: 1px solid #ddd;
  -webkit-border-radius: 0px;
  border-radius: 0px;
}
.text-input--underbar:disabled {
  opacity: 0.3;
  cursor: default;
  pointer-events: none;
  border: none;
  background-color: transparent;
  border-bottom: 1px solid #ddd;
}
.text-input--underbar:disabled::-webkit-input-placeholder,
.text-input--underbar:disabled::-moz-placeholder,
.text-input--underbar:disabled:-ms-input-placeholder {
  color: #999;
  border: none;
  background-color: transparent;
}
.text-input--underbar:invalid {
  border: 1px solid #ddd;
  color: #1f1f21;
  border: none;
  background-color: transparent;
  border-bottom: 1px solid #ddd;
}