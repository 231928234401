/* onsenUI reset */
.page__background {
  background-color: #fff;
  height: 50px;
}

.navigation-bar {
  background: #77a835;
  height: 55px;
}
.navigation-bar .navigation-bar__title {
  height: 55px;
  line-height: 55px;
  color: #fff;
  font-weight: bold;
  text-align: center;
  font-size: 18px;
}
.navigation-bar span.ng-scope {
  display: block;
  margin-top: -3px;
  line-height: 0;
  font-size: 12px;
}
.navigation-bar .toolbar-button {
  padding: 0;
}

.navigation-bar__center:first-child,
.navigation-bar__center:last-child {
  width: 100%;
}

ons-toolbar + .page__background + .page__content {
  top: 55px;
}

.page--menu-page__content {
  background: #222;
  color: #fff;
}

.toolbar-button {
  color: #fff;
}
.toolbar-button img {
  vertical-align: middle;
}

.menu-list {
  background: #222;
  border: none;
  color: #fff;
}

.menu-item {
  border: none !important;
  color: #fff;
  line-height: 1;
  padding: 15px 10px;
  min-height: auto;
}

.modal {
  display: block;
}

.loading {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

/* layout parts */
.mt0 {
  margin-top: 0px;
}

.mb0 {
  margin-bottom: 0px;
}

.pt0 {
  padding-top: 0px;
}

.pb0 {
  padding-bottom: 0px;
}

.mt5 {
  margin-top: 5px;
}

.mb5 {
  margin-bottom: 5px;
}

.pt5 {
  padding-top: 5px;
}

.pb5 {
  padding-bottom: 5px;
}

.mt10 {
  margin-top: 10px;
}

.mb10 {
  margin-bottom: 10px;
}

.pt10 {
  padding-top: 10px;
}

.pb10 {
  padding-bottom: 10px;
}

.mt15 {
  margin-top: 15px;
}

.mb15 {
  margin-bottom: 15px;
}

.pt15 {
  padding-top: 15px;
}

.pb15 {
  padding-bottom: 15px;
}

.mt20 {
  margin-top: 20px;
}

.mb20 {
  margin-bottom: 20px;
}

.pt20 {
  padding-top: 20px;
}

.pb20 {
  padding-bottom: 20px;
}

.mt25 {
  margin-top: 25px;
}

.mb25 {
  margin-bottom: 25px;
}

.pt25 {
  padding-top: 25px;
}

.pb25 {
  padding-bottom: 25px;
}

.mt30 {
  margin-top: 30px;
}

.mb30 {
  margin-bottom: 30px;
}

.pt30 {
  padding-top: 30px;
}

.pb30 {
  padding-bottom: 30px;
}

.mt35 {
  margin-top: 35px;
}

.mb35 {
  margin-bottom: 35px;
}

.pt35 {
  padding-top: 35px;
}

.pb35 {
  padding-bottom: 35px;
}

.mt40 {
  margin-top: 40px;
}

.mb40 {
  margin-bottom: 40px;
}

.pt40 {
  padding-top: 40px;
}

.pb40 {
  padding-bottom: 40px;
}

.mt45 {
  margin-top: 45px;
}

.mb45 {
  margin-bottom: 45px;
}

.pt45 {
  padding-top: 45px;
}

.pb45 {
  padding-bottom: 45px;
}

.mt50 {
  margin-top: 50px;
}

.mb50 {
  margin-bottom: 50px;
}

.pt50 {
  padding-top: 50px;
}

.pb50 {
  padding-bottom: 50px;
}

.mt55 {
  margin-top: 55px;
}

.mb55 {
  margin-bottom: 55px;
}

.pt55 {
  padding-top: 55px;
}

.pb55 {
  padding-bottom: 55px;
}

.mt60 {
  margin-top: 60px;
}

.mb60 {
  margin-bottom: 60px;
}

.pt60 {
  padding-top: 60px;
}

.pb60 {
  padding-bottom: 60px;
}

.mt65 {
  margin-top: 65px;
}

.mb65 {
  margin-bottom: 65px;
}

.pt65 {
  padding-top: 65px;
}

.pb65 {
  padding-bottom: 65px;
}

.mt70 {
  margin-top: 70px;
}

.mb70 {
  margin-bottom: 70px;
}

.pt70 {
  padding-top: 70px;
}

.pb70 {
  padding-bottom: 70px;
}

.mt75 {
  margin-top: 75px;
}

.mb75 {
  margin-bottom: 75px;
}

.pt75 {
  padding-top: 75px;
}

.pb75 {
  padding-bottom: 75px;
}

.mt80 {
  margin-top: 80px;
}

.mb80 {
  margin-bottom: 80px;
}

.pt80 {
  padding-top: 80px;
}

.pb80 {
  padding-bottom: 80px;
}

.mt85 {
  margin-top: 85px;
}

.mb85 {
  margin-bottom: 85px;
}

.pt85 {
  padding-top: 85px;
}

.pb85 {
  padding-bottom: 85px;
}

.mt90 {
  margin-top: 90px;
}

.mb90 {
  margin-bottom: 90px;
}

.pt90 {
  padding-top: 90px;
}

.pb90 {
  padding-bottom: 90px;
}

.mt95 {
  margin-top: 95px;
}

.mb95 {
  margin-bottom: 95px;
}

.pt95 {
  padding-top: 95px;
}

.pb95 {
  padding-bottom: 95px;
}

.mt100 {
  margin-top: 100px;
}

.mb100 {
  margin-bottom: 100px;
}

.pt100 {
  padding-top: 100px;
}

.pb100 {
  padding-bottom: 100px;
}

html {
  font-family: "Hiragino Kaku Gothic ProN", Meiryo, sans-serif;
  font-size: 16px;
}

.pageContentsWrapper {
  background: #fff;
}

.pageContents {
  width: 100%;
  padding: 0 10px;
  box-sizing: border-box;
}

.pageFlexContents {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 0 20px;
  box-sizing: border-box;
}
.pageFlexContents .pageFlexContents__item {
  width: 100%;
}

.toolBar {
  background: #222;
}
.toolBar__iconClose {
  position: relative;
  display: inline-block;
  padding-right: 16px;
  color: #fff;
  vertical-align: middle;
  text-decoration: none;
  font-size: 15px;
}
.toolBar__iconClose .toolBar__iconClose:before, .toolBar__iconClose .toolBar__iconClose:after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  content: "";
  vertical-align: middle;
}
.toolBar__iconClose:before {
  left: 3px;
  width: 7px;
  height: 1px;
  background: #fff;
}
.toolBar__iconClose:after {
  left: 3px;
  width: 6px;
  height: 6px;
  border-top: 1px solid #fff;
  border-right: 1px solid #fff;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.menu-item img {
  width: auto;
  height: 12px;
  margin-right: 10px;
}
.menu-item .menu-item__icon {
  margin-right: 10px;
  font-size: 12px;
  vertical-align: middle;
}

.menuIcon {
  display: inline-block;
  text-align: center;
  margin: 0 10px;
  width: 40px;
  height: 55px;
}

.iconSize--close {
  font-size: 44px;
  color: #fff;
}

.heading01 {
  font-size: 32px;
  color: #666;
}
.heading01--modal {
  color: #fff;
}

.heading02 {
  font-size: 28px;
  color: #666;
}
.heading02--modal {
  color: #fff;
}

.heading03 {
  font-size: 20px;
  color: #666;
}
.heading03--modal {
  color: #fff;
}

.heading04 {
  font-size: 18px;
  font-weight: bold;
  color: #666;
}
.heading04--modal {
  color: #fff;
}

.heading05 {
  font-size: 14px;
  font-weight: bold;
  color: #666;
}
.heading05--modal {
  color: #fff;
}

.iconSmall {
  font-size: 12px;
  vertical-align: 0.1em;
}
.iconSmall--right {
  margin-right: 5px;
}
.iconSmall--left {
  margin-left: 5px;
}

.iconNomal {
  font-size: 14px;
  vertical-align: 0.1em;
}
.iconNomal--right {
  margin-right: 5px;
}
.iconNomal--left {
  margin-left: 5px;
}

.iconBig {
  font-size: 16px;
  vertical-align: 0.1em;
}
.iconBig--right {
  margin-right: 5px;
}
.iconBig--left {
  margin-left: 5px;
}

.textLeft {
  text-align: left;
}

.textRight {
  text-align: right;
}

.textCenter {
  text-align: center;
}

.textContents {
  color: #333;
  line-height: 1.5;
  font-size: 15px;
}

.textContents--block {
  box-sizing: border-box;
  width: 100%;
  padding: 10px;
  background: #F9FBE7;
}

.commentBlock {
  position: relative;
}
.commentBlock__item {
  position: relative;
  width: 100%;
  padding: 20px;
  border-radius: 6px;
  box-sizing: border-box;
  background: #eee;
  z-index: 3;
}
.commentBlock:before {
  display: block;
  position: absolute;
  bottom: -25px;
  left: 20px;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background: #eee;
  z-index: 1;
  content: "";
}
.commentBlock:after {
  display: block;
  position: absolute;
  bottom: -30px;
  left: 39px;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background: #fff;
  z-index: 2;
  content: "";
}

.characterCommentBlock {
  position: relative;
  color: #333;
}
.characterCommentBlock__item {
  position: relative;
  width: 100%;
  padding: 20px;
  border-radius: 6px;
  box-sizing: border-box;
  background: #eee;
  z-index: 3;
}
.characterCommentBlock:before {
  display: block;
  position: absolute;
  box-shadow: -17px 0 0 0 #eee inset;
  top: -22px;
  right: 20px;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background: transparent;
  -webkit-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  transform: rotate(30deg);
  z-index: 1;
  content: "";
}

.characterPhoto {
  position: relative;
  text-align: right;
  -webkit-animation: moveY 4s infinite alternate linear;
  animation: moveY 4s infinite alternate linear;
}

@-webkit-keyframes moveY {
  0% {
    top: 0px;
  }
  50% {
    top: 15px;
  }
  100% {
    top: 0px;
  }
}

@keyframes moveY {
  0% {
    top: 0px;
  }
  50% {
    top: 15px;
  }
  100% {
    top: 0px;
  }
}
.characterModal .modal__content {
  display: block;
}

.missionBlock {
  position: relative;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 0 20px;
  border: 4px solid #fff;
  background: #d1c0a5;
  width: 278px;
  height: 300px;
  color: #333;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-name: openmission01;
  animation-name: openmission01;
}
.missionBlock__title {
  font-size: 20px;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-name: openmission03;
  animation-name: openmission03;
}
.missionBlock__txt {
  text-align: left;
  line-height: 1.5;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-name: openmission03;
  animation-name: openmission03;
}
.missionBlock:before {
  display: block;
  background: url(../img/common/icon/icon_mail01.png) 0 0 no-repeat;
  background-size: 100px auto;
  position: absolute;
  right: -37px;
  top: -50px;
  width: 100px;
  height: 106px;
  z-index: 2;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-name: openmission03;
  animation-name: openmission03;
  content: "";
}
.missionBlock:after {
  display: block;
  background: url(../img/common/img/img_coverfoot01.png) 0 0 no-repeat;
  background-size: 278px auto;
  position: absolute;
  left: -24px;
  bottom: -5px;
  width: 278px;
  height: 18px;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-name: openmission02;
  animation-name: openmission02;
  content: "";
}

@-webkit-keyframes openmission01 {
  0% {
    height: 0;
  }
  100% {
    height: 300px;
  }
}

@keyframes openmission01 {
  0% {
    height: 0;
  }
  100% {
    height: 300px;
  }
}
@-webkit-keyframes openmission02 {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}
@keyframes openmission02 {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}
@-webkit-keyframes openmission03 {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes openmission03 {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.imgBlock {
  width: 100%;
  height: auto;
}

.detailBasic {
  width: 100%;
}
.detailBasic__title {
  color: #666;
}

.graphBlock {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
}
.graphBlock__title .iconBig {
  color: #FDB66A;
  -webkit-animation: anim-rotate 3s ease-out;
  animation: anim-rotate 3s ease-out;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}
.graphBlock__description {
  color: #555;
  font-size: 14px;
  margin-top: 5px;
}
.graphBlock__item {
  width: 100%;
  border-radius: 2px;
  background: #eee;
}

@-webkit-keyframes anim-rotate {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  10% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }
  20% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  35% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  50% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  65% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }
  80% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  95% {
    -webkit-transform: translateY(-2px);
    transform: translateY(-2px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes anim-rotate {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  10% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }
  20% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  35% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  50% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  65% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }
  80% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  95% {
    -webkit-transform: translateY(-2px);
    transform: translateY(-2px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
.buttonLink {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.buttonLink__item {
  width: 50%;
}
.buttonLink__item:first-child {
  margin-right: 5px;
}
.buttonLink__item:last-child {
  margin-left: 5px;
}

.BesideBlock {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.BesideBlock__item {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
}
.BesideBlock--plural .BesideBlock__item {
  margin-left: 10px;
}
.BesideBlock--plural .BesideBlock__item:first-child {
  margin-left: 0;
}
.BesideBlock--mission {
  color: #999;
  text-align: right;
  font-size: 12px;
}
.BesideBlock--mission:before {
  display: inline-block;
  content: "0";
}

.circleDetail__item {
  margin-top: 10px;
}
.circleDetail__item--style01:before {
  display: inline-block;
  margin-right: 5px;
  width: 14px;
  height: 14px;
  border-radius: 7px;
  background: #e051a3;
  z-index: 1;
  content: "";
}
.circleDetail__item--style02:before {
  display: inline-block;
  margin-right: 5px;
  width: 14px;
  height: 14px;
  border-radius: 7px;
  background: #51e08e;
  z-index: 1;
  content: "";
}

.graphItem {
  display: block;
  box-sizing: border-box;
  height: 25px;
  padding-left: 10px;
  line-height: 25px;
  color: #fff;
  text-shadow: 1px 1px 1px #666;
  vertical-align: middle;
  -webkit-animation-name: longgrah;
  animation-name: longgrah;
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -webkit-animation-timing-function: ease;
  animation-timing-function: ease;
}
.graphItem--item01 {
  background: #5154e0;
}
.graphItem--item02 {
  background: #519be0;
}
.graphItem--item03 {
  background: #51d8e0;
}
.graphItem--item04 {
  background: #51e08e;
}
.graphItem--item05 {
  background: #73e051;
}
.graphItem--item06 {
  background: #cae051;
}

@-webkit-keyframes longgrah {
  0% {
    width: 0;
  }
}

@keyframes longgrah {
  0% {
    width: 0;
  }
}
.circleBlock {
  display: block;
  width: 110px;
  height: 110px;
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
}
.circleBlock__item {
  fill: transparent;
  cx: 50%;
  cy: 50%;
  r: 50;
  -webkit-animation-name: circlegrah;
  animation-name: circlegrah;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: ease;
  animation-timing-function: ease;
  stroke-width: 8;
}
.circleBlock__item--style01 {
  stroke: #e051a3;
}
.circleBlock__item--style02 {
  stroke: #51e08e;
}

.circleBox {
  display: block;
  position: relative;
  width: 110px;
  margin: 0 auto;
}
.circleBox__text {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  height: 30px;
  line-height: 30px;
  vertical-align: middle;
  margin: auto;
  font-size: 14px;
}
.circleBox__text span {
  display: block;
  line-height: 1;
}

@-webkit-keyframes circlegrah {
  0% {
    r: 0;
  }
  100% {
    r: 50;
  }
}

@keyframes circlegrah {
  0% {
    r: 0;
  }
  100% {
    r: 50;
  }
}
/* form parts */
.form__textBox {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-sizing: border-box;
  width: 100%;
  min-height: 44px;
  padding: 5px;
  color: #333;
  border-radius: 2px;
  border: 1px solid #ccc;
  font-size: 14px;
}
.form__textBox:focus {
  box-shadow: rgba(119, 224, 119, 0.8) 0px 0px 10px -2px;
}
.form__textDate {
  position: relative;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-sizing: border-box;
  width: 100%;
  min-height: 44px;
  padding: 5px;
  border-radius: 2px;
  border: 1px solid #ccc;
  font-size: 14px;
}
.form__textDate:after {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  border-top: 2px solid #ccc;
  border-right: 2px solid #ccc;
  -webkit-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  transform: rotate(135deg);
  position: absolute;
  right: 10px;
  top: 50%;
  margin-top: -4px;
}
.form__textDate:focus {
  box-shadow: rgba(119, 224, 119, 0.8) 0px 0px 10px -2px;
}

.radioButtonSet {
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 2px;
  padding: 3px;
  line-height: 0;
}
.radioButtonSet--wide {
  width: 100%;
  box-sizing: border-box;
}
.radioButtonSet--wide li {
  width: 50%;
}
.radioButtonSet li {
  display: inline-block;
  line-height: 1;
}
.radioButtonSet input[type=radio] {
  display: none;
}
.radioButtonSet label {
  display: block;
  position: relative;
  margin: 0;
  padding: 5px 20px;
  font-size: 14px;
  color: #666;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
}
.radioButtonSet label:before {
  content: attr(data-label);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  display: block;
  width: auto;
  height: auto;
  margin-top: 5px;
  text-align: center;
  color: transparent;
  background: transparent;
}
.radioButtonSet label:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: auto;
  background: #fff;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}
.radioButtonSet input[type=radio]:checked + label:before {
  color: #fff;
}
.radioButtonSet input[type=radio]:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: auto;
  background: #26a69a;
  border-radius: 100%;
  -webkit-transform: scale(2);
  -ms-transform: scale(2);
  transform: scale(2);
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.missionRange {
  width: 100%;
}

/* button parts */
.buttonpositive, .buttonpositive:hover, .buttonNormal, .buttonNormal:hover, .buttonNegative, .buttonNegative:hover, .buttonBack, .buttonBack:hover {
  display: block;
  color: #fff;
  text-align: center;
  font-weight: bold;
}

.button {
  width: 100%;
  min-height: 44px;
  line-height: 44px;
}

.buttonpositive {
  background-color: #e79923;
}
.buttonpositive:hover {
  background-color: #e79923;
}

.buttonNormal {
  background-color: #26a69a;
}
.buttonNormal:hover {
  background-color: #26a69a;
}

.buttonBack {
  color: #333;
  background-color: #ccc;
}
.buttonBack:hover {
  color: #333;
  background-color: #ccc;
}

.buttonNegative {
  color: #333;
  background-color: #fff;
  border: solid 2px #a2a2a2;
}
.buttonNegative:hover {
  color: #333;
  background-color: #fff;
  border: solid 2px #a2a2a2;
}
.listBox {
  padding: 10px 0 16px 0;
  border-top: 2px solid#DADADA;
  border-bottom: 2px solid #DADADA;
  background-color: #FDFDFD;
}
.listButton {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  flex-align: center;
  width: 90%;
  margin-left: 10%;
  border-radius: 4px;
}
.listButton__item {
  box-sizing: border-box;
  width: 100%;
  /*padding: 15px 10px;*/
  padding: 10px 20px 10px 10px;
  border-radius: 6px;
  border: 1px solid #929292;
  background: #FBFBFB;
  box-shadow: 0 1px 4px rgba(175, 174, 174, 0.2);
  transition-property: color,background;
  transition-duration: 0.5s;
}
.listButton__item:before {
  position: absolute;
  display: block;
  left: 3%;
}
.listButton__item:nth-child(1):before {
  content: '1位';
}
.listButton__item:nth-child(2):before {
  content: '2位';
}
.listButton__item:nth-child(3):before {
  content: '3位';
}
.listButton__item:nth-child(4):before {
  content: '4位';
}
.listButton__item.ui-sortable-helper:before {
  content: '';
}
.listButton--select .listButton__item {
  list-style-type: decimal;
  list-style-position: inside;
}

.mainButton {
  position: relative;
  width: 100%;
  height: 200px;
}
.mainButton--lock:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6) url(../img/common/icon/icon_lock01.png) 50% 50% no-repeat;
  background-size: 38px 50px;
  z-index: 1;
  width: 100%;
  height: 100%;
}
.mainButton__text {
  box-sizing: border-box;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 0 20px;
  height: 50px;
  line-height: 50px;
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
  font-size: 16px;
  vertical-align: middle;
}
.mainButton--diagnosis {
  background: url(../img/common/img/img_result01.gif) 0 0 no-repeat;
  background-size: cover;
}
.mainButton--diagnosis .mainButton__text {
  padding: 0 10px;
  font-size: 14px;
}
.mainButton--mission {
  background: url(../img/common/img/img_main01.png) 0 0 no-repeat;
  background-size: cover;
}
.mainButton--mission .mainButton__text:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 20px;
  right: 10px;
  background: url(../img/common/icon/icon_mission01.png) 0 0 no-repeat;
  background-size: 55px 38px;
  z-index: 1;
  width: 55px;
  height: 38px;
}