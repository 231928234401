/* This is a CSS file */

.radio-button > input[type="radio"] {
  position: absolute;
  overflow: hidden;
  right: 0px;
  top: 0px;
  left: 0px;
  bottom: 0px;
  padding: 0;
  border: 0;
  opacity: 0.001;
  z-index: 1;
  vertical-align: top;
  outline: none;
  width: 100%;
  height: 100%;
  margin: 0;
  -webkit-appearance: none;
  appearance: none;
  height: 0px;
  width: 0px;
}
.radio-button > input[type="radio"]:active,
.radio-button > input[type="radio"]:focus {
  outline: 0;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}
.radio-button > input[type="radio"]:checked + .radio-button__checkmark:after {
  opacity: 1;
}
.radio-button > input[type="radio"]:checked + .radio-button__checkmark:before {
  background: transparent;
  border: none;
}
.radio-button {
  position: relative;
  display: inline-block;
  vertical-align: top;
  cursor: default;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative;
  overflow: hidden;
  line-height: 24px;
  text-align: left;
  margin: 10px 0;
}
.radio-button__checkmark:before {
  content: '';
  position: absolute;
  -webkit-border-radius: 100%;
  border-radius: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  width: 24px;
  height: 24px;
  background: transparent;
  border: none;
  -webkit-border-radius: 16px;
  border-radius: 16px;
  left: 0;
}
.radio-button__checkmark {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  position: relative;
  display: inline-block;
  vertical-align: top;
  cursor: default;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-size: 17px;
  position: relative;
  overflow: hidden;
  position: relative;
  width: 24px;
  height: 24px;
  background: transparent;
  pointer-events: none;
}
.radio-button > input[type="radio"]:checked + .radio-button__checkmark {
  background: rgba(0,0,0,0);
}
.radio-button__checkmark:after {
  content: '';
  position: absolute;
  -webkit-border-radius: 100%;
  border-radius: 100%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  top: 6px;
  left: 5px;
  opacity: 0;
  width: 12px;
  height: 6px;
  background: transparent;
  border: 3px solid rgba(24,103,194,0.81);
  border-width: 2px;
  border-top: none;
  border-right: none;
  -webkit-border-radius: 0px;
  border-radius: 0px;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.radio-button > input[type="radio"]:disabled + .radio-button__checkmark {
  opacity: 0.3;
  cursor: default;
  pointer-events: none;
}